<template>
  <div>
    <headers />
    <div class="bg">
      <img class="h1" :src="require('@/assets/image/partner/hend.png')" alt="" />
      <!-- <div class="h1">111</div> -->
      <!-- <div class="h2">111</div> -->
      <div class="line1"></div>
      <div class="if">
        <div class="text1">
          <div>如果你:</div>
          <div class="con">· 正在寻找快实施、稳利润的产品<br/>· 正在寻找低投入、高回报项目<br/>· 想要加入数字化转型浪潮</div>
        </div>
        <div class="text2">欢迎你加入  “数字厂长” 城市合伙人</div>
      </div>
      <div class="line1 blue"></div>
      <div class="titleHed hen1">
        <div class="titleline"></div>
        <div class="titlename">这里有</div>
        <div class="titleline"></div>
      </div>
      <div class="therehas">
        <div class="box">
          <div class="imgBox">
            <img class="img1" :src="require('@/assets/image/partner/img1.png')" alt="" />
          </div>          
          <div class="tag">现实红利</div>
          <div class="text">数百万利润回报</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img2" :src="require('@/assets/image/partner/img2.png')" alt="" />
          </div> 
          <div class="tag">一天上线</div>
          <div class="text">快速应用的可推广可复<br/>制的制造业数字化平台</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img3" :src="require('@/assets/image/partner/img3.png')" alt="" />
          </div>  
          <div class="tag">携手共赢</div>
          <div class="text">在数转浪潮中共享红利</div>  
        </div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">什么产品?</div>
        <div class="titleline"></div>
      </div>
      <div class="shuzi">“数字厂长”——中小型制造企业数字化转型最佳选择。</div>
      <div class="whatprod">
        <div class="box">
          <div class="imgBox">
            <img class="img4" :src="require('@/assets/image/partner/img4.png')" alt="" />
          </div>          
          <div class="tag">懂</div>
          <div class="text">更懂制造业的数字化<br/>转型，千家企业现场<br/>实践，通用性的<br/>SAAS平台。</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img5" :src="require('@/assets/image/partner/img5.png')" alt="" />
          </div>   
          <div class="tag">视</div>
          <div class="text">全程可视化，全程知晓<br/>工厂运作，软硬件<br/>结合全面显示。</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img6" :src="require('@/assets/image/partner/img6.png')" alt="" />
          </div>   
          <div class="tag">通</div>
          <div class="text">供、产、销全面打<br/>通，达成厂内物料守<br/>恒，信息共通。</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img7" :src="require('@/assets/image/partner/img7.png')" alt="" />
          </div>   
          <div class="tag">快</div>
          <div class="text">一天平台上线，十分<br/>钟操作上手，半月全<br/>线理顺，快速数字化<br/>实施.</div>
        </div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline" style="width: 8%;"></div>
        <div class="titlename">对数字化不熟悉？对市场没信心？没关系，四大帮助全力助您成功</div>
        <div class="titleline" style="width: 8%;"></div>
      </div>
      <div class="helpyou">
        <div class="box">
          <div class="imgBox">
            <img class="img8" :src="require('@/assets/image/partner/img8.png')" alt="" />
          </div>          
          <div class="tag">全面培训</div>
          <div class="text">专职人员全面培训，完<br/>整课程助您快速上手</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img9" :src="require('@/assets/image/partner/img9.png')" alt="" />
          </div>          
          <div class="tag">技术支持</div>
          <div class="text">专家人员现场支持，实<br/>施完全承担无需操心</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img10" :src="require('@/assets/image/partner/img10.png')" alt="" />
          </div>          
          <div class="tag">人员助力</div>
          <div class="text">专业人员全面助力,会<br/>销面销辅助支撑</div>
        </div>
        <div class="box">
          <div class="imgBox">
            <img class="img11" :src="require('@/assets/image/partner/img11.png')" alt="" />
          </div>          
          <div class="tag">辅助获利</div>
          <div class="text">合伙人提供渠道，公司销<br/>售人员上阵让您坐享提成</div>
        </div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">适用行业</div>
        <div class="titleline"></div>
      </div>
      <div class="shuzi">“数字厂长”广泛适用多种制造业，包括但不限于：</div>
      <div class="tagbox">
        <div class="tag">机械设备</div>
        <div class="tag">轨道交通</div>
        <div class="tag">汽车制造</div>
        <div class="tag">专用设备</div>
        <div class="tag">铸造</div>
        <div class="tag">半导体</div>
        <div class="tag">注塑冲压</div>
        <div class="tag">泵</div>
        <div class="tag">阀门机械制造</div>
        <div class="tag">医疗器械</div>
        <div class="tag">智能制造</div>
        <div class="tag">非金属矿物制品</div>
        <div class="tag">电气机械和器材</div>
        <div class="tag">传感设备</div>
        <div class="tag">电器设备</div>
        <div class="tag">冶金</div>
        <div class="tag">集成电路</div>
        <div class="tag">高分子材料</div>
        <div class="tag">机加工和模具</div>
        <div class="tag">通用航空</div>
        <div class="tag">金属材料</div>
        <div class="tag">轴承</div>
        <div class="tag">齿轮制造</div>
        <div class="tag">通用零部件制造</div>
        <div class="tag">仪器仪表</div>
        <div class="tag">家具制造</div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">申请条件</div>
        <div class="titleline"></div>
      </div>
      <div class="shuzi">如果您:</div>
      <div class="stepBox">
        <div class="stepline"></div>
        <div class="step">
          <div class="num">01</div>
          <div class="steptext">是在上述细分行业中的某一个或多个行业内有丰富的企业人脉资源的个人或团队；</div>
        </div>
        <div class="step">
          <div class="num">02</div>
          <div class="steptext">或者在本区域内上述行业的行业协会任会长、副会长、理事长或者秘书长等职位；</div>
        </div>
        <div class="step">
          <div class="num">03</div>
          <div class="steptext">或者您在上述细分行业有大量的下游企业客户资源；</div>
        </div>
        <div class="welcome">那么欢迎您加入“数字厂长”城市合伙人的队伍，共享制造业中小企业数字化转型的红利。</div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">城市合伙人的合作流程</div>
        <div class="titleline"></div>
      </div>
      <div class="stepBox">
        <div class="stepline stepline2"></div>
        <div class="step">
          <div class="num">01</div>
          <div class="steptext">城市合伙人完成报名信息表的填写；</div>
        </div>
        <div class="step">
          <div class="num">02</div>
          <div class="steptext">经筛选后，完成沟通，并签署城市合伙人合作</div>
        </div>
        <div class="step">
          <div class="num">03</div>
          <div class="steptext">我们对城市合伙人进行集中培训；备份</div>
        </div>
        <div class="step">
          <div class="num">04</div>
          <div class="steptext">城市合伙人开展业务。</div>
        </div>
      </div>
      <div class="titleHed hen2">
        <div class="titleline"></div>
        <div class="titlename">城市合伙人合作方式</div>
        <div class="titleline"></div>
      </div>
      <div class="stepBox">
        <div class="stepline stepline3"></div>
        <div class="step">
          <div class="num">01</div>
          <div class="steptext">信息渠道合伙人：提供有效客户信息，并保证畅通信息渠道，帮助完成订单，收取销售佣金</div>
        </div>
        <div class="step">
          <div class="num">02</div>
          <div class="steptext">商业战略合伙人：自主完成销售，独立完成合同签订，收取商业利润。</div>
        </div>
        <div class="step">
          <div class="num">03</div>
          <div class="steptext">全面战略合伙人：自主完成销售、实施工作，独立经营区域内销售、实施、售后的整体工作，形成战略合作，获得整体利润。</div>
        </div>
      </div>
      <div class="jieshi">本活动解释权归数壤科技（苏州）有限公司所有</div>
    </div>
    <footers />
  </div>
</template>
<script>
import headers from '@/components/head';
import footers from '@/components/foot';
import redirect from "@/utils/redirect";
import pcRem from "@/utils/pcrem";
export default {
  mixins: [redirect,pcRem],
  components: {
    headers,
    footers
  },
}
</script>
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}
.bg{
  width: 100%;
  // height: px2rem(5920);
  height: 206.8rem;
  background: url(../../assets/image/partner/bg.png) center no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.h1{
  margin: px2rem(131) auto 0;
  width: px2rem(1920);
  height: px2rem(221);
  display: block;
}
// .h2{
//   margin-top: px2rem(37);
//   height: px2rem(105);
// }
.line1{
  position: relative;
  margin: px2rem(29) auto 0;
  width: px2rem(650);
  height: px2rem(1);
  border-top: px2rem(1) solid;
  border-image: linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
  &:after{
    position: absolute;
    top: px2rem(-4.5);
    right: px2rem(198);
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
  }
  &.blue{
    &::after{
      background: #9EFCFF;
      left: px2rem(198);
    }
  }
}
.if{
  margin: auto;
  width: px2rem(650);  
  font-size: px2rem(32);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.8);
  line-height: px2rem(72);
  .text1{
    display: flex;
    .con{
      margin-left: px2rem(60);
    }
  }
}
.titleHed{
  &.hen1{
    margin-top: px2rem(144);
  }
  &.hen2{
    margin-top: px2rem(75);
  }
  position: relative;
  margin: auto;
  width: px2rem(1200);
  display: flex;
  align-items: center;
  &::before{
    position: absolute;
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
    left: 0;
    top: px2rem(16);
  }
  &::after{
    position: absolute;
    content: '';
    width: px2rem(10);
    height: px2rem(10);
    background: #FFFFFF;
    border-radius: 50%;
    right: 0;
    top: px2rem(16);
  }
  .titleline{
    width: 50%;
    height: 1px;
    background:  #FFFFFF;
  }
  .titlename{
    margin: 0 px2rem(40);
    font-size: px2rem(32);
    font-family: MicrosoftYaHei;
    color: #FFFFFF;
    line-height: px2rem(42);
    word-break: keep-all;
  }
}
.therehas{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(334);
    height: px2rem(324);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .img1{
    width: px2rem(89);
    height: px2rem(100);
  }
  .img2{
    width: px2rem(88);
    height: px2rem(82);
  }
  .img3{
    width: px2rem(96);
    height: px2rem(96);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.shuzi{
  margin-top: px2rem(20);
  text-align: center;  
  font-size: px2rem(24);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.85);
  line-height: px2rem(31);
}
.whatprod{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(286);
    height: px2rem(386);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img4{
    width: px2rem(94);
    height: px2rem(94);
  }
  .img5{
    width: px2rem(88);
    height: px2rem(88);
  }
  .img6{
    width: px2rem(82);
    height: px2rem(82);
  }
  .img7{
    width: px2rem(84);
    height: px2rem(84);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.helpyou{
  margin: px2rem(40) auto 0;
  width: px2rem(1200);
  display: flex;
  justify-content: space-between;
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px2rem(286);
    height: px2rem(324);
    border-radius: px2rem(30);
    border: 2px solid #9EFCFF;
  }
  .imgBox{
    margin-top: px2rem(40) ;
    height: px2rem(100);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img8{
    width: px2rem(94);
    height: px2rem(94);
  }
  .img9{
    width: px2rem(88);
    height: px2rem(79);
  }
  .img10{
    width: px2rem(80);
    height: px2rem(77);
  }
  .img11{
    width: px2rem(92);
    height: px2rem(92);
  }
  .tag{
    margin-top: px2rem(30);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
  .text{
    margin-top: px2rem(10);
    font-size: px2rem(24);
    color: rgba(255,255,255,0.85);
    line-height: px2rem(31);
    text-align: center;
  }
}
.tagbox{
  display: flex;
  flex-wrap: wrap;
  padding: px2rem(5);
  margin: px2rem(40) auto;
  width: px2rem(1200);
  border-radius: px2rem(30);
  border: 2px solid #9EFCFF;
  box-sizing: border-box;
  .tag{    
    margin: px2rem(10);
    padding: 0 px2rem(30);
    height: px2rem(60);
    line-height: px2rem(60);
    border-radius: px2rem(30);
    border: px2rem(2) solid #9DFBFF;
    font-size: px2rem(32);
    color: #FFFFFF;
    background: #004EA7;
  }
}
.stepBox{
  margin: px2rem(42) auto 0;
  width: px2rem(1200);
  position: relative;
  .stepline{
    position: absolute;
    z-index: 1;
    top: px2rem(-20);
    left: px2rem(50);
    width: px2rem(1);
    height: px2rem(480);
    border-left: 1px solid;
    border-image: linear-gradient(180deg, rgba(204, 253, 255, 0), rgba(204, 253, 255, 1), rgba(177, 252, 255, 1), rgba(157, 251, 255, 0)) 1 1;
    &.stepline2{
      height: px2rem(550);
    }
    &.stepline3{
      height: px2rem(425);
    }
  }
  .step{
    position: relative;
    z-index: 2;
    margin-top: px2rem(80);
    display: flex;
    align-items: center;
    &:first-child{
      margin-top: 0;
    }
    .num{
      width: px2rem(100);
      height: px2rem(60);
      line-height: px2rem(60);
      text-align: center;
      border-radius: px2rem(30);
      border: px2rem(2) solid #9DFBFF;
      font-size: px2rem(32);
      color: #FFFFFF;
      background: #004EA7;
    }
    .steptext{
      margin-left: px2rem(40);
      width: 42rem;      
      color: #FFFFFF;
      line-height: px2rem(37);      
      font-size: px2rem(28);
      font-family: MicrosoftYaHei;
    }
  }
}
.welcome{
  margin-top: px2rem(102);
  width: px2rem(1000);
  height: px2rem(66);
  text-align: center;
  line-height: px2rem(66);
  border-radius: px2rem(40);
  border: px2rem(2) solid #9EFCFF;
  font-size: px2rem(24);
  font-family: MicrosoftYaHei;
  color: rgba(255,255,255,0.85);

}
.jieshi{
  margin-top: px2rem(180);
  height: px2rem(50);
  background: #000D09;
  font-size: px2rem(20);
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  line-height: px2rem(50);
}
// ::v-deep .footer{
//   position: relative;
//   z-index: 1;
//   margin-top: -16rem
// }
</style>
