<template>
  <nav id="navBar" transparent="true" class="ietFAJ">
    <div class="container">
      <div class="dDHerZ">
        <img class="logo" :src="require('@/assets/image/logo.png')" alt="" />
      </div>
      <div class="bQsAkm">
        <ul class="DDrDg">
          <li
            v-for="(item, index) of menuList"
            :class="{ active: item.active }"
            :key="index"
          >
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="bQsAkm">
        <ul class="DDrDg">
          <li>
            <a href="#"><i class="el-icon-search"></i></a>
          </li>
          <li class="login"><a href="https://cyhlw.digi-land.cn" target="_blank">登录</a></li>
          <li><a href="https://cyhlw.digi-land.cn" target="_blank">注册</a></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          name: "首页",
          link: "/",
          routeName: "home",
          active: false,
        },
        {
          name: "产品概览",
          link: "/productoverview",
          routeName: "productoverview",
          active: false,
        },
        {
          name: "客户案例",
          link: "/customerstories",
          routeName: "customerstories",
          active: false,
        },
        {
          name: "走进数壤",
          link: "/enterdigiland",
          routeName: "enterdigiland",
          active: false,
        },
        {
          name: "数壤课堂",
          link: "/class",
          routeName: "class",
          active: false,
        },
        {
          name: "数壤合伙人",
          link: "/partner",
          routeName: "partner",
          active: false,
        },
        {
          name: "数壤代理商",
          link: "/agent",
          routeName: "agent",
          active: false,
        },
      ],
    };
  },
  created() {
    this.menuList.map((x) => {
      x.active = false;
      if (x.routeName == this.$route.name) {
        x.active = true;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio + rem;
}
.ietFAJ {
  height: px2rem(80);
  border: none;
  width: 100%;
  margin-bottom: px2rem(-80);
  position: fixed;
  top: 0px;
  z-index: 1;
  border-radius: 0px;
  padding: 0px px2rem(20);
  backdrop-filter: none;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(24px);
  box-sizing: border-box;

  .logo {
    width: px2rem(128);
    height: px2rem(52);
  }

  .container {
    margin-top: 0.56rem;
    max-width: 98vw;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: auto !important;

    li {
      margin: 0 px2rem(20);
      font-size: px2rem(20);
      height: px2rem(28);
      line-height: px2rem(28);
      padding: 0 px2rem(30);
      border-radius: px2rem(20);
      a {
        color: white;
        font-weight: 300;
      }
      &.active {
        border: 1px solid rgb(2, 185, 128);
        a {
          color: rgb(2, 185, 128) !important;
        }
      }
    }
  }

  .DDrDg {
    height: 100%;
    display: flex !important;
    flex-direction: row !important;
    -webkit-box-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;
  }

  .login {
    position: relative;

    &::after {
      position: absolute;
      content: "|";
      right: px2rem(-20);
      color: white;
    }
  }
}
</style>
