let pcRem = {
  beforeRouteEnter(to, from, next) {
    // 获取当前设备的宽度，设置rem的根字体大小
    let width = window.innerWidth
    width = width <= 1200 ? 1200 : width
    const htmlObj = document.getElementsByTagName('html')[0]
    htmlObj.style.fontSize = width / 76.8 + 'px'

    // 对resize事件进行浏览器兼容处理
    if (document.createEvent) {
      var event = document.createEvent('HTMLEvents')
      event.initEvent('resize', true, true)
      window.dispatchEvent(event)
    } else if (document.createEventObject) {
      window.fireEvent('onresize')
    }

    // 监听页面resize事件，重新设置rem的根字体大小
    window.onresize = function () {
      let width = window.innerWidth
      width = width <= 1200 ? 1200 : width
      htmlObj.style.fontSize = width / 76.8 + 'px'
    }
    next()
  },
  created() {
    // 获取当前设备的宽度，设置rem的根字体大小
    let width = window.innerWidth
    width = width <= 1200 ? 1200 : width
    const htmlObj = document.getElementsByTagName('html')[0]
    htmlObj.style.fontSize = width / 76.8 + 'px'

    // 对resize事件进行浏览器兼容处理
    if (document.createEvent) {
      var event = document.createEvent('HTMLEvents')
      event.initEvent('resize', true, true)
      window.dispatchEvent(event)
    } else if (document.createEventObject) {
      window.fireEvent('onresize')
    }

    // 监听页面resize事件，重新设置rem的根字体大小
    window.onresize = function () {
      let width = window.innerWidth
      width = width <= 1200 ? 1200 : width
      htmlObj.style.fontSize = width / 76.8 + 'px'
      }
  },
  destroyed() {
    const htmlObj = document.getElementsByTagName('html')[0]
    htmlObj.style.fontSize = ''
    window.onresize = null
  },
}
 
export default pcRem
