<template>
  <div class="footer">
      <div class="footer_part">
        <img :src="logo" class="footer_logo" />
      </div>
      <div class="footer_part">
        <div class="footer_h1">走进数壤</div>
        <ul>
          <li><a href="/enterdigiland">数壤介绍</a></li>
          <!-- <li>投资简介</li> -->
          <!-- <li>数壤团队</li> -->
          <li><a href="/enterdigiland#growup">发展历程</a></li>
          <li><a href="/enterdigiland#soil">数字土壤</a></li>
          <li><a href="/enterdigiland#join">加入数壤</a></li>
          <li><a href="/enterdigiland">联系我们</a></li>
        </ul>
      </div>
      <div class="footer_part">
        <div class="footer_h1">产品导航</div>
        <ul>
          <li><a href="/productoverview#manage">数字厂长（管理型）</a></li>
          <li><a href="/productoverview#market">数字厂长（营销型）</a></li>
          <!-- <li>数壤商城</li> -->
          <li><a href="/productoverview#maps">产业地图</a></li>
          <li><a href="/productoverview#twin">数字孪生</a></li>
          <li><a href="/productoverview">数壤元宇宙</a></li>
        </ul>
      </div>
      <div class="footer_part">
        <div class="footer_h1">客户案例</div>
        <ul>
          <li><a href="/customerstories">企业案例</a></li>
          <li><a href="/customerstories">政府案例</a></li>
          <li><a href="/customerstories">媒体报道</a></li>
          <li><a href="/customerstories">产品更新</a></li>
        </ul>
      </div>
      <div class="footer_part">
        <div class="footer_h1">数壤课堂</div>
        <ul>
          <li><a href="/class#expert">专家简介</a></li>
          <li><a href="/class#classes">数壤小课堂</a></li>
          <li><a href="/class#courses">数壤精品课</a></li>
          <li><a href="/class#institute">数壤研究院</a></li>
        </ul>
      </div>
      <div class="footer_part">
        <div class="footer_h1">联系方式</div>
        <ul class="lianxi">
          <li>联系人 : 郭经理 </li>
          <li>E-mail：bing.guo@digi-land.cn</li>
          <li>合作热线：18866287467</li>
          <li>地址 : 苏州市吴中大道1463号</li>
          <li>苏州(太湖)软件产业园智慧谷园<br/>区2号楼6楼</li>
        </ul>
        <div class="footer_picInfo">
        <div class="footer_pic">
          <img :src="gongzhonghao" />
          <div class="footer_info">公众号</div>
        </div>
        <div class="footer_pic">
          <img :src="douyinghao" />
          <div class="footer_info">抖音号</div>
        </div>
      </div>
      </div>
      
      <div class="icp">沪ICP备2021021769号-3</div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/image/logo.png"),
      gongzhonghao: require("@/assets/image/gongzhonghao.png"),
      douyinghao: require("@/assets/image/douyinghao.png"),
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
//1920*1080为设计稿，头部字体大小为25px
$ratio: 1920 / 76.8; // 缩放比   25

// 返回对应屏幕下的rem值
@function px2rem($px) {
  @return $px / $ratio +rem;
}
a {
  color: rgba(255,255,255,0.85);
}
.icp{
  position: absolute;
  bottom: px2rem(10);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: px2rem(20);
  z-index: 9;
}

.footer {
  position: relative;
  padding-bottom: px2rem(24);
  width: 100%;
  height: px2rem(450);
  background: #001810;
  display: flex;
  justify-content: space-around;
  padding-top: px2rem(40);
  .footer_part {
    .footer_logo {
      width: px2rem(133);
      height: px2rem(56);
    }
    .footer_h1 {
      // width: px2rem(80);
      height: px2rem(28);
      font-size: px2rem(20);
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: px2rem(28);
      margin-bottom: px2rem(20);
    }
    ul {
      li {
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: px2rem(22);
        margin-bottom: px2rem(20);
      }
      &.lianxi{
        li{
          margin-bottom: 0;
          line-height: px2rem(28);
        }
      }
    }
  }
  .footer_picInfo {
    margin-top: px2rem(10);
    display: flex;
    .footer_pic {
      margin-right: px2rem(20);
      text-align: center;
      .footer_info {
        margin-top: px2rem(10);
        height: px2rem(22);
        font-size: px2rem(16);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: px2rem(22);
      }
    }
  }
}
</style>
