let redirect = {
  beforeRouteEnter(to, from, next) {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = Agents.filter(i => userAgentInfo.includes(i));
    if (getArr.length) {
      if (to.path == '/') {
        next(`/h5/home`);
      } else {
        next(`/h5${to.path}`);
      }
      
    }
    next()
  }
  
}
 
export default redirect
